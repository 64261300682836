import React, { useState } from "react";
import { Link, navigate } from "gatsby";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const ContactForm = () => {
  const [state, setState] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    message: ""
  });

  const validateEmail = str => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return str && re.test(String(str).toLowerCase());
  };

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
    console.log(state);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    if (state.name === "") {
      setErrors({ ...errors, name: "Dette feltet kan ikke være tomt" });
    } else if (!validateEmail(state.email)) {
      setErrors({ ...errors, email: "Dette er ikke en gyldig email" });
    } else if (state.message === "") {
      setErrors({ ...errors, message: "Dette feltet kan ikke være tomt" });
    }

    if (state.name !== "" && validateEmail(state.email) && state.message !== "") {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...state
        })
      })
        .then(() => navigate(form.getAttribute("action")))
        .catch(error => alert(error));
    } else {
      return;
    }
  };

  return (
    <form
      className="form"
      name="Kontaktskjema"
      method="post"
      data-netlify="true"
      netlify
      action="/takk/"
      onSubmit={handleSubmit}
      //autoComplete="off"
    >
      <input type="hidden" name="form-name" value="Kontaktskjema" />
      <p>
        <label className="form__label">Navn</label>
        {errors.name !== "" && <span>{errors.name}</span>}
        <input onChange={handleChange} type="text" name="name" />
      </p>
      <p>
        <label className="form__label">Email</label>
        {errors.email !== "" && <span>{errors.email}</span>}
        <input onChange={handleChange} type="email" name="email" />
      </p>

      <p>
        <label className="form__label">Melding</label>
        {errors.message !== "" && <span>{errors.message}</span>}
        <textarea onChange={handleChange} name="message" />
      </p>

      <p>
        <button className="form__button" type="submit">
          Send
        </button>
      </p>
    </form>
  );
};

export default ContactForm;
