import React from "react";
import HeroNoImage from "../components/hero-no-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import Container from "../components/container";
import ContactForm from "../components/contactForm";
import BlogPostPreviewGrid from "../components/blog-post-preview-grid";
import GraphQLErrorList from "../components/graphql-error-list";

import "../styles/layout.scss";

export const query = graphql`
  query ContactPageQuery {
    posts: allSanityPost(
      limit: 3
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const ContactPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  console.log(data);
  return (
    <Layout>
      <SEO title="Kontakt" />
      <HeroNoImage />
      <Container>
        <div className="contact-page">
          <h1>Kontakt meg</h1>
          <div className="contanct-page__flex">
            <div className="contact-page__form-container">
              <ContactForm />
            </div>
            <div className="contact-page__image-container">
              <img src={require("../assets/garden.jpg")} alt="Bente Grove" />
            </div>
          </div>
        </div>
        <div className="blog-preview-home">
          {postNodes && (
            <BlogPostPreviewGrid
              title="Siste blogginnlegg"
              nodes={postNodes}
              browseMoreHref="/blog/"
              page={"index"}
            />
          )}
        </div>
      </Container>
    </Layout>
  );
};

export default ContactPage;
